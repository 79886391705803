<script lang="ts">
    import { inertia } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    import MyMarket from './MyMarket.svelte';

    interface User {
        username: string;
        name: string;
    }

    interface Post {
        text: string;
    }

    interface Auction {
        start_price: number;
    }

    interface Offering {
        user_id?: string;
        user?: User;
        post_id?: string;
        post?: Post;
        auction: Auction;
        expiry: string;
    }

    export let myOfferings: Offering[];
    let open: boolean = false;
    let filterOption: string = 'All';
    let filteredData: Offering[] = [];

    function toggleDropdown(): void {
        open = !open;
    }

    function selectOption(id: string): void {
        filterOption = id;
        open = false;
        if (id === 'Users') {
            filteredData = myOfferings.filter((record) => record.post_id === null);
        } else if (id === 'Posts') {
            filteredData = myOfferings.filter((record) => record.post_id !== null);
        } else {
            filteredData = myOfferings;
        }
    }

    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    };
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);

</script>

<svelte:component this="{MyMarket}" />

<div class="h-screen flex flex-col bg-gray-100">
    <div class="flex-1 flex items-start justify-center p-4">
        <div class="w-full max-w-4xl bg-white rounded-xl shadow-2xl border border-gray-200">
            <div class="table-wrapper">
                {#if filteredData.length > 0}

                    <div class="p-4">
                        <div class="overflow-x-auto">
                            <table class="w-full table-auto border-collapse">
                                <thead>
                                    <tr class="border-b border-gray-200">
                                        <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Post</th>
                                        <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Amount</th>
                                        <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Expiry</th>
                                        <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {#each filteredData as myOffering}
                                        <tr class="hover:bg-gray-50 transition-colors duration-200">
                                            <td class="py-3 px-4 text-gray-800">
                                                <a href="/{myOffering.post_id}" class="text-indigo-600 hover:text-indigo-800 font-medium transition-colors" use:inertia>{myOffering.post.text}</a>
                                            </td>
                                            <td class="py-3 px-4 text-gray-600">{myOffering.post.price}</td>
                                            <td class="py-3 px-4 text-gray-600">
                                                {myOffering.listing.expiry_time ? dateFormatter.format(new Date(myOffering.listing.expiry_time)) : '-'}
                                            </td>
                                            <td class="py-3 px-4">
                                                <a href="/markets" class="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors" use:inertia>Visit</a>
                                            </td>
                                        </tr>
                                    {/each}
                                </tbody>
                            </table>
                        </div>
                    </div>


                {:else}
                    <p class="p-4 text-center text-gray-500">No Record Found</p>
                {/if}
            </div>
        </div>
    </div>
</div>
